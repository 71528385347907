import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

const AffiliateManagement = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateManagement")),
);
const AffiliateReport = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateReport")),
);
const AffiliateSetting = ScreenLoader(
  lazy(() => import("../screens/affiliate-screen/AffiliateSetting")),
);

export const AffiliatePromotionRoute = () => ({
  path: "affiliate",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "affiliate-management",
      element: (
        <RoleGuard
          requiredPermission="affiliateManagement"
          requiredActions={["isRead"]}
        >
          <AffiliateManagement />
        </RoleGuard>
      ),
    },
    {
      path: "affiliate-report",
      element: (
        <RoleGuard
          requiredPermission="affiliateReport"
          requiredActions={["isRead"]}
        >
          <AffiliateReport />
        </RoleGuard>
      ),
    },
    {
      path: "affiliate-setting",
      element: (
        <RoleGuard
          requiredPermission="affiliateManagement"
          requiredActions={["isCreate"]}
        >
          <AffiliateSetting />
        </RoleGuard>
      ),
    },
    {
      path: "affiliate-setting/:id",
      element: (
        <RoleGuard
          requiredPermission="affiliateManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <AffiliateSetting />
        </RoleGuard>
      ),
    },
  ],
});
