import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";

//Member
const MemberAdd = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberAdd")),
);
const MemberChangePassword = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberChangePassword")),
);
const MemberChangeAccount = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberChangeAccount")),
);
const MemberManagement = ScreenLoader(
  lazy(() => import("../screens/member-screen/MemberManagement")),
);
const MemberDetail = ScreenLoader(
  lazy(() => import("../screens/member-screen/member-detail/MemberDetail")),
);
//maping the member route completely
export const MemberRoute = () => ({
  path: "member",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "member-management",
      element: (
        <RoleGuard
          requiredPermission="memberManagement"
          requiredActions={["isRead"]}
        >
          <MemberManagement />
        </RoleGuard>
      ),
    },
    {
      path: "member-add",
      element: (
        <RoleGuard
          requiredPermission="memberManagement"
          requiredActions={["isCreate"]}
        >
          <MemberAdd />
        </RoleGuard>
      ),
    },

    {
      path: "member-detail/:id",
      element: (
        <RoleGuard
          requiredPermission="memberManagement"
          requiredActions={["isRead", "isUpdate"]}
        >
          <MemberDetail />
        </RoleGuard>
      ),
    },
    {
      path: "change-password/:id",
      element: (
        <RoleGuard
          requiredPermission="memberManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <MemberChangePassword />
        </RoleGuard>
      ),
    },
    {
      path: "change-account/:id",
      element: (
        <RoleGuard
          requiredPermission="memberManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <MemberChangeAccount />
        </RoleGuard>
      ),
    },
  ],
});
