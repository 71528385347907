import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";

//Announcement
const AnnouncementAdd = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementAdd")),
);
const AnnouncementManagement = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementManagement")),
);
const AnnouncementEdit = ScreenLoader(
  lazy(() => import("../screens/announcement-screen/AnnouncementEdit")),
);

export const AnnoucementRoute = () => ({
  path: "announcement",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "announcement-add",
      element: (
        <RoleGuard
          requiredPermission="websiteAnnounce"
          requiredActions={["isCreate", "isRead"]}
        >
          <AnnouncementAdd />
        </RoleGuard>
      ),
    },
    {
      path: "announcement-management",
      element: (
        <RoleGuard
          requiredPermission="websiteAnnounce"
          requiredActions={["isCreate"]}
        >
          <AnnouncementManagement />
        </RoleGuard>
      ),
    },
    {
      path: "announcement-edit/:id",
      element: (
        <RoleGuard
          requiredPermission="websiteAnnounce"
          requiredActions={["isRead", "isDelete"]}
        >
          <AnnouncementEdit />
        </RoleGuard>
      ),
    },
  ],
});
