import { Navigate } from "react-router";
import { ScreenLoader } from "../utils/ScreenLoader";
import { lazy } from "react";

const LoginPage = ScreenLoader(lazy(() => import("../screens/LoginPage")));

//maping the root route completely
export const RootRoute = () => ({
  path: "/",
  element:
    localStorage.getItem("NAGA_ADMIN:token") !== null ? (
      <Navigate to="/dashboard" replace />
    ) : (
          <LoginPage />
    ),
});
