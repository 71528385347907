import { useNavigate } from "react-router-dom";
import svg from "../assets/404.svg";

const PageNotFound = () => {
    const navigate = useNavigate();

    const handleBackToDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen text-center">
            {/* <h1 className="text-gray-100 text-9xl">404</h1> */}
            
            {/* <h1 className="text-gray-100 text-5xl">ไม่มีสิทธิ์เข้าถึง</h1> */}
            <img src={svg} alt="ไม่มีสิทธิ์เข้าถึง" className="w-1/2 h-auto max-w-xs md:max-w-md" />
            <button
                onClick={handleBackToDashboard}
                className="mt-5 px-4 py-2 bg-black font-medium text-white shadow-sm hover:bg-indigo-500 rounded"
            >
                กลับสู่หน้าหลัก
            </button>
        </div>
    );
};

export default PageNotFound;
