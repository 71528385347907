import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

//Website
const PaymentGatewayList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayList")),
);
const PaymentGatewaySetting = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewaySetting")),
);
const PaymentGatewayAutoList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoList")),
);
const PaymentGatewayAutoTransactions = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoTransactions"),
  ),
);
const PaymentGatewayAutoCreate = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoCreate")),
);
const PaymentGatewayAutoTransfer = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoTransfer")),
);

export const PaymentGatewayRoute = () => ({
  path: "payment-gateway",
  element: (
    
        <AuthGuard>
          <Outlet />
        </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: (
        <RoleGuard
          requiredPermission="paymentGateway"
          requiredActions={["isRead"]}
        >
          <PaymentGatewayList />
        </RoleGuard>
      ),
    },
    {
      path: "setting",
      element: (
        <RoleGuard
          requiredPermission="paymentGateway"
          requiredActions={["isRead"]}
        >
          <PaymentGatewaySetting />
        </RoleGuard>
      ),
    },
  ],
});
