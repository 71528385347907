import { lazy } from "react";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";
import { Outlet } from "react-router";
import NotifticationGroupService from "../contexts/services/notification-service/NotificationGroupService";
import { AdminListServiceContextProvider } from "../contexts/services/employee-service/AdminListService";

const NotificationGroupListScreen = ScreenLoader(
  lazy(
    () =>
      import(
        "../screens/notification-group-screen/NotificationGroupListScreen"
      ),
  ),
);

const NotificationGroupSettingScreen = ScreenLoader(
  lazy(
    () =>
      import(
        "../screens/notification-group-screen/NotificationGroupSettingScreen"
      ),
  ),
);

export const NotificationGroupRoute = () => ({
  path: "group-notification",
  element: (
    <AuthGuard>
      <NotifticationGroupService>
        <AdminListServiceContextProvider>
          <Outlet />
        </AdminListServiceContextProvider>
      </NotifticationGroupService>
    </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: <NotificationGroupListScreen />,
    },
    {
      path: "add",
      element: <NotificationGroupSettingScreen />,
    },
    {
      path: "edit/:id",
      element: <NotificationGroupSettingScreen />,
    },
  ],
});
