import React, { createContext, useCallback, useContext, useState } from "react";
import { useLoading } from "../../LoadingContext";
import axios from "axios";
import { AuthContext } from "../../AuthContext";

export interface IActivity {
  _id: string;
  admin: {
    _id: string;
    username: string;
  };
  ip: string;
  path: string;
  objectBefore: Record<string, any>;
  objectAfter: Record<string, any>;
  metadataEntity: string;
  metadataAction: string;
  roleId: {
    _id: string;
    roleName: string;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface IActivityCollection<D> {
  initialized: boolean;
  data: D;
}

interface IPagination<D> {
  totalCount: number;
  activities: D[];
}

interface IActivityContext {
  collection: {
    entities: IActivityCollection<{ entities: string[] }>;
    actions: IActivityCollection<{ actions: string[] }>;
    list: IActivityCollection<IPagination<IActivity>>;
  };
  getList: (payload: Record<string, any>) => Promise<void>;

  getEnitityActivity: () => Promise<void>;
  getActionActivity: () => Promise<void>;
}

export const ActivityLogsServiceContext =
  createContext<IActivityContext | null>(null);

export default function ActivityLogsService({
  children,
}: {
  children: React.ReactNode;
}) {
  const [collectionList, setCollectionList] = useState<
    IActivityContext["collection"]["list"]
  >({
    initialized: false,
    data: {
      activities: [],
      totalCount: 0,
    },
  });
  const [collectionEntities, setCollectionEntities] = useState<
    IActivityContext["collection"]["entities"]
  >({
    initialized: false,
    data: {
      entities: [],
    },
  });
  const [collectionAction, setCollectionAction] = useState<
    IActivityContext["collection"]["actions"]
  >({
    initialized: false,
    data: {
      actions: [],
    },
  });

  const authContext = useContext(AuthContext);
  const loading = useLoading();

  const onFetch = useCallback(
    async (url: string, body: Record<string, string | number>) => {
      loading.push();
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_URL}${url}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${authContext.token}`,
            },
            validateStatus: () => true,
          },
        );

        return resp.data;
      } catch (e) {
        return {};
      } finally {
        loading.pop();
      }
    },
    [authContext],
  );

  async function getList(payload: Record<string, any>) {
    const { data } = await onFetch("/api/v1/activity/list", payload);
    if (data) {
      setCollectionList({
        initialized: true,
        data,
      });
    }
  }
  async function getEnitityActivity() {
    const { data } = await onFetch("/api/v1/activity/list-entities", {});
    if (data) {
      setCollectionEntities({
        initialized: true,
        data,
      });
    }
  }
  async function getActionActivity() {
    const { data } = await onFetch("/api/v1/activity/list-actions", {});
    if (data) {
      setCollectionAction({
        initialized: true,
        data,
      });
    }
  }

  return (
    <ActivityLogsServiceContext.Provider
      value={{
        collection: {
          list: collectionList,
          entities: collectionEntities,
          actions: collectionAction,
        },
        getList,
        getEnitityActivity,
        getActionActivity,
      }}
    >
      {children}
    </ActivityLogsServiceContext.Provider>
  );
}
