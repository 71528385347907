import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

const WebsiteSystemSetting = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteSystemSetting")),
);
const GameProviders = ScreenLoader(
  lazy(() => import("../screens/website-screen/GameProviders")),
);
const GameType = ScreenLoader(
  lazy(() => import("../screens/website-screen/GameType")),
);
const WebsiteConfig = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteConfig")),
);
const WebsiteBanner = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteBanner")),
);
const WebsiteBannerAdd = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteBannerAdd")),
);
const WebsiteCustomize = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsiteCustomize")),
);
const WebsitePopup = ScreenLoader(
  lazy(() => import("../screens/website-screen/WebsitePopup")),
);
const DistributorProvider = ScreenLoader(
  lazy(() => import("../screens/distributor-screen/DistributorProvider")),
);

export const WebsiteRoute = () => ({
  path: "website",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "game-providers",
      element: (
        <RoleGuard
          requiredPermission="providerDashboard"
          requiredActions={["isRead"]}
        >
          <DistributorProvider />
        </RoleGuard>
      ),
    },
    {
      path: "game-types",
      element: (
        <RoleGuard
          requiredPermission="providerDashboard"
          requiredActions={["isRead"]}
        >
          <GameType />
        </RoleGuard>
      ),
    },
    {
      path: "core-system",
      element: (
        <RoleGuard
          requiredPermission="websiteConfigAndCoreSystem"
          requiredActions={["isRead"]}
        >
          <WebsiteSystemSetting />
        </RoleGuard>
      ),
    },
    {
      path: "website-config",
      element: (
        <RoleGuard
          requiredPermission="websiteConfigAndCoreSystem"
          requiredActions={["isRead"]}
        >
          <WebsiteConfig />
        </RoleGuard>
      ),
    },
    {
      path: "website-banner",
      element: (
        <RoleGuard
          requiredPermission="websiteBanner"
          requiredActions={["isRead"]}
        >
          <WebsiteBanner />
        </RoleGuard>
      ),
    },
    {
      path: "website-banner-add",
      element: (
        <RoleGuard
          requiredPermission="websiteBanner"
          requiredActions={["isCreate"]}
        >
          <WebsiteBannerAdd />
        </RoleGuard>
      ),
    },
    {
      path: "website-customize",
      element: (
        <RoleGuard
          requiredPermission="websiteCustomize"
          requiredActions={["isRead"]}
        >
          <WebsiteCustomize />
        </RoleGuard>
      ),
    },
    {
      path: "website-popup",
      element: (
        <RoleGuard
          requiredPermission="websitePopup"
          requiredActions={["isRead"]}
        >
          <WebsitePopup />
        </RoleGuard>
      ),
    },
  ],
});
