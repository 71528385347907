// import axios from "axios";
// import {
//   createContext,
//   FC,
//   useCallback,
//   useContext,
//   useState,
//   useRef,
// } from "react";
// import { AuthContext } from "./AuthContext";
// import { useNavigate, useNavigation } from "react-router-dom";

// export const SelfContext = createContext({
//   user: {
//     username: "",
//     type: "",
//     id: "",
//     roleName: undefined,
//   },
//   onSyncSelf: () => {},
// });

// export const SelfContextProvider: FC<any> = ({ children }) => {
//   const navigation = useNavigate();
//   const contextAuth = useContext(AuthContext);
//   const [user, setUser] = useState({
//     username: "",
//     type: "",
//     id: "",
//     roleName: undefined,
//   });
//   const shouldVerify = useRef(true);

//   const onSyncSelf = useCallback(async () => {
//     if (shouldVerify.current) {
//       shouldVerify.current = false;
//       try {
//         const request = await axios.get(
//           `${process.env.REACT_APP_API_URL}/api/v1/authorization/self`,
//           {
//             headers: {
//               Authorization: `Bearer ${contextAuth.token}`,
//             },
//           }
//         );

//         if (request.data.data) setUser(request.data.data);
//         const userData = {
//           ...request.data.data,
//           roleName: request.data.data.role.roleName,

//         };
//         console.log("userData",userData)
//         setUser(userData)
//       } catch (error) {
//         console.log((error as any).response.status);
//         console.log((error as any).response);
//         if ((error as any).response.status === 401) {
//           contextAuth.onReset();
//           localStorage.removeItem("NAGA_ADMIN:token");
//           navigation("/")
//         }
//       }
//     }
//   }, [contextAuth.token]);

//   return (
//     <SelfContext.Provider
//       value={{
//         user,
//         onSyncSelf,
//       }}>
//       {children}
//     </SelfContext.Provider>
//   );
// };

// import axios from "axios";
// import {
//   createContext,
//   FC,
//   useCallback,
//   useContext,
//   useState,
//   useRef,
//   useEffect,
// } from "react";
// import { AuthContext } from "./AuthContext";
// import { useNavigate } from "react-router-dom";

// interface PermissionDetail {
//   isRead: boolean;
//   isCreate: boolean;
//   isUpdate: boolean;
//   isDelete: boolean;
// }
// interface Permission {
//   [key: string]: PermissionDetail;
// }

// interface Role {
//   permission: Permission;
// }

// interface User {
//   username: string;
//   type: string;
//   id: string;
//   roleName?: string;
//   role: Role | null;
//   permissions?: Permission;
// }

// export const SelfContext = createContext({
//   user: {
//     username: "",
//     type: "",
//     id: "",
//     roleName: undefined,
//     role: null,
//     permissions: {},
//   } as User,
//   isLoading: true,
//   onSyncSelf: () => {},
// });

// export const SelfContextProvider: FC<any> = ({ children }) => {
//   const [isFetched, setIsFetched] = useState(false);
//   const navigation = useNavigate();
//   const contextAuth = useContext(AuthContext);
//   const [user, setUser] = useState({
//     username: "",
//     type: "",
//     id: "",
//     roleName: undefined,
//     role: null,
//     permissions: {} as Permission,
//   });
//   const [isLoading, setIsLoading] = useState(true);

//   const onSyncSelf = useCallback(async () => {
//     try {
//       const token = contextAuth.token;
//       if (!token) {
//         setIsLoading(false);
//         return;
//       }

//       const request = await axios.get(
//         `${process.env.REACT_APP_API_URL}/api/v1/authorization/self`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       const userData = {
//         ...request.data.data,
//         roleName: request.data.data.role.roleName,
//         role: request.data.data.role,
//         permissions: request.data.data.role.permissions,

//       };
//       // console.log("userData",userData)
//       setUser(userData);
//       setIsFetched(true); // ตั้งค่า isFetched เพื่อป้องกันการเรียกซ้ำ

//     } catch (error) {
//       const response = (error as any).response;
//       if (response && response.status === 401) {
//         contextAuth.onReset();
//         localStorage.removeItem("NAGA_ADMIN:token");
//         navigation("/");
//       }
//       console.error("Unexpected error", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [contextAuth, navigation]);

//   useEffect(() => {
//     onSyncSelf();
//   }, [onSyncSelf,isFetched]);

//   return (
//     <SelfContext.Provider value={{ user, isLoading, onSyncSelf }}>
//       {children}
//     </SelfContext.Provider>
//   );
// };

import axios from "axios";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

interface PermissionDetail {
  isRead: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}
interface Permission {
  [key: string]: PermissionDetail;
}

interface Role {
  permission: Permission;
}

interface WalletAdmin {
  finalBalance: number;
}

interface User {
  username: string;
  type: string;
  id: string;
  roleName?: string;
  role: Role | null;
  permissions?: Permission;
  wallet: WalletAdmin | null;
}

export const SelfContext = createContext({
  user: {
    username: "",
    type: "",
    id: "",
    roleName: undefined,
    role: null,
    wallet: null,
    permissions: {},
  } as User,
  isLoading: true,
  isFetched: false,
  onSyncSelf: () => {},
});

export const SelfContextProvider: FC<any> = ({ token, children }) => {
  const navigation = useNavigate();
  const contextAuth = useContext(AuthContext);
  const [user, setUser] = useState({
    username: "",
    type: "",
    id: "",
    roleName: undefined,
    role: null,
    wallet: null,
    permissions: {} as Permission,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // เพิ่มสถานะ isFetched

  const onSyncSelf = useCallback(async () => {
    // console.log("Fetch self");
    try {
      const token = contextAuth.token;
      // console.log("token", token);
      if (!token) {
        // ถ้าข้อมูลถูก fetch แล้วจะไม่เรียกอีก
        setIsLoading(false);
        return;
      }

      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/authorization/self`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userData = {
        ...request.data.data,
        roleName: request.data.data.role.roleName,
        role: request.data.data.role,
        permissions: request.data.data.role?.permissions, // ตรวจสอบตรงนี้
      };
      // console.log("API Response Data:", request.data.data);

      setUser(userData);
      setIsFetched(true);
    } catch (error) {
      const response = (error as any).response;
      console.log(error);
      if (
        (response && response.status === 401) ||
        (response && response.status === 500)
      ) {
        contextAuth.onReset();
        localStorage.removeItem("NAGA_ADMIN:token");
        navigation("/");
      }
      console.error("Unexpected error", error);
    } finally {
      setIsLoading(false);
    }
  }, [contextAuth, navigation]); // เพิ่ม isFetched ใน dependencies

  // const shouldFetchSelf = useRef(true);
  // useEffect(() => {
  //   if (shouldFetchSelf.current) {
  //     onSyncSelf();
  //     shouldFetchSelf.current = false;
  //   }
  // }, [onSyncSelf]); // ตรวจสอบ isFetched ก่อนเรียก

  return (
    <SelfContext.Provider value={{ user, isFetched, isLoading, onSyncSelf }}>
      {children}
    </SelfContext.Provider>
  );
};
