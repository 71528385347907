import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

//refund
const ReturnManagement = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundManagement")),
);
const ReturnReport = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundReport")),
);
const ReturnSetting = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundSetting")),
);
const ReturnEdit = ScreenLoader(
  lazy(() => import("../screens/refund-screen/RefundEdit")),
);

export const RefundPromotionRoute = () => ({
  path: "refund",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "refund-management",
      element: (
        <RoleGuard
          requiredPermission="refundManagement"
          requiredActions={["isRead"]}
        >
          <ReturnManagement />
        </RoleGuard>
      ),
    },
    {
      path: "refund-report",
      element: (
        <RoleGuard
          requiredPermission="refundReport"
          requiredActions={["isRead"]}
        >
          <ReturnReport />
        </RoleGuard>
      ),
    },
    {
      path: "refund-setting",
      element: (
        <RoleGuard
          requiredPermission="refundManagement"
          requiredActions={["isCreate"]}
        >
          <ReturnSetting />
        </RoleGuard>
      ),
    },
    {
      path: "refund-setting/:id",
      element: (
        <RoleGuard
          requiredPermission="refundManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <ReturnEdit />
        </RoleGuard>
      ),
    },
  ],
});
