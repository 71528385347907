import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

const EmployeeAdd = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeAdd")),
);
const EmployeeManagement = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeManagement")),
);
const AdminLog = ScreenLoader(
  lazy(() => import("../screens/employee-screen/AdminLog")),
);

const RoleManagement = ScreenLoader(
  lazy(() => import("../screens/employee-screen/RoleManagement")),
);

const RoleAdd = ScreenLoader(
  lazy(() => import("../screens/employee-screen/RoleAdd")),
);
const RoleEdit = ScreenLoader(
  lazy(() => import("../screens/employee-screen/RoleEdit")),
);

export const EmployeeRoute = () => ({
  path: "employee",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "employee-add",
      element: (
        <RoleGuard
          requiredPermission="employeeManagement"
          requiredActions={["isCreate"]}
        >
          <EmployeeAdd />
        </RoleGuard>
      ),
    },
    {
      path: "employee-management",
      element: (
        <RoleGuard
          requiredPermission="employeeManagement"
          requiredActions={["isRead"]}
        >
          <EmployeeManagement />
        </RoleGuard>
      ),
    },
    {
      path: "admin-log",
      element: (
        <RoleGuard requiredPermission="adminLog" requiredActions={["isRead"]}>
          <AdminLog />
        </RoleGuard>
      ),
    },
    {
      path: "role-add",
      element: (
        <RoleGuard
          requiredPermission="roleManagement"
          requiredActions={["isCreate"]}
        >
          <RoleAdd />
        </RoleGuard>
      ),
    },
    {
      path: "role-edit/:id",
      element: (
        <RoleGuard
          requiredPermission="roleManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <RoleEdit />
        </RoleGuard>
      ),
    },
    {
      path: "role-management",
      element: (
        <RoleGuard
          requiredPermission="roleManagement"
          requiredActions={["isRead"]}
        >
          <RoleManagement />
        </RoleGuard>
      ),
    },
  ],
});
