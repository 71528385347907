import { OtpContextProvider } from "../contexts/OtpContext";
import { SelfPreOtpContextProvider } from "../contexts/SelfPreOtpContext";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";
import { lazy } from "react";

const LoginOtpPage = ScreenLoader(
  lazy(() => import("../screens/LoginOtpPage")),
);

export const OtpRoute = () => ({
  path: "otp",
  element: (
    // <AuthGuard>
    <SelfPreOtpContextProvider>
      <OtpContextProvider>
        <LoginOtpPage />
      </OtpContextProvider>
    </SelfPreOtpContextProvider>
    // </AuthGuard>
  ),
});
