import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { RoleGuard } from "../contexts/RoleGuard"; // Import RoleGuard

const CreditTransaction = ScreenLoader(
  lazy(() => import("../screens/CreditTransaction")),
);
export const CreditTrancsactionRoute = () => ({
  path: "credit-transaction",
  element: (
    <AuthGuard>
      <RoleGuard
        requiredPermission="creditTransaction"
        requiredActions={["isRead"]}
      >
        <CreditTransaction />
      </RoleGuard>
    </AuthGuard>
  ),
});
