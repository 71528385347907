import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";

const PreTransactionManagement = ScreenLoader(
  lazy(
    () => import("../screens/pretransaction-screen/PreTransactionManagement"),
  ),
);
const PreTransactionRequest = ScreenLoader(
  lazy(
    () =>
      import(
        "../screens/pretransactionrequest-screen/PreTransactionRequestManagement"
      ),
  ),
);
const ReconcileDeposit = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileUserDeposit")),
);
const ReconcileWithdraw = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileUserWithdraw")),
);
const PreTransactionNotMatchManagement = ScreenLoader(
  lazy(
    () =>
      import(
        "../screens/pretransaction-not-match-screen/PreTransactionNotMatchManagement"
      ),
  ),
);
//maping the pre-transaction route completely
export const PreTransactionRoute = () => ({
  path: "pre-transaction",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "deposit-withdraw",
      element: (
        <RoleGuard
          requiredPermission="pretransactionDepositWithdraw"
          requiredActions={["isRead"]}
        >
          <PreTransactionManagement />
        </RoleGuard>
      ),
    },
    {
      path: "request",
      element: (
        <RoleGuard
          requiredPermission="pretransactionRequest"
          requiredActions={["isRead", "isUpdate"]}
        >
          <PreTransactionRequest />
        </RoleGuard>
      ),
    },
    {
      path: "matching",
      element: (
        <RoleGuard
          requiredPermission="pretransactionNotMatch"
          requiredActions={["isRead"]}
        >
          <PreTransactionNotMatchManagement />
        </RoleGuard>
      ),
    },
    {
      path: "reconcile-deposit",
      element: (
        <RoleGuard
          requiredPermission="pretransactionReconcileDeposit"
          requiredActions={["isRead", "isUpdate"]}
        >
          <ReconcileDeposit />
        </RoleGuard>
      ),
    },
    {
      path: "reconcile-withdraw",
      element: (
        <RoleGuard
          requiredPermission="pretransactionReconcileWithdraw"
          requiredActions={["isRead", "isUpdate"]}
        >
          <ReconcileWithdraw />
        </RoleGuard>
      ),
    },
  ],
});
