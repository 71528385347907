import { createContext, FC, useCallback, useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";

type AdminListQuery = {
  page: number;
  size: number;
  username: string;
};

type AdminListServiceContextProps = {
  query: AdminListQuery;
  collection: any[];
  roles: { _id: string; roleName: string; }[];
  fetchRoles: Function;
  onFetch: Function;
  setCollection: (collection: any[]) => void; 
  updateAdminRole: (id: string, roleId: string) => Promise<void>; // เพิ่ม updateAdminRole


};

const defaultContext: AdminListServiceContextProps = {
  query: {
    page: 1,
    size: 10,
    username: "",
  },
  collection: [],
  roles: [],
  fetchRoles: () => {},
  onFetch: () => {},
  setCollection: () => {}, // เพิ่ม setCollection
  updateAdminRole: async () => {}, // เพิ่มค่าเริ่มต้นให้กับ updateAdminRole


};

const getServiceEndpoint = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/admin/list`;

const getRolesEndpoint = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/role/list`;

export const AdminListServiceContext = createContext(defaultContext);

export const AdminListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]); // เปลี่ยน type ของ roles เป็น any[]
  // const [query] = useState(defaultContext.query);
  const [query] = useState<AdminListQuery>(defaultContext.query); // กำหนด type ให้กับ query ด้วยเช่นกัน
  const authContext = useContext(AuthContext);
  const loading = useLoading();

  const onFetch = useCallback(
    async (data: AdminListQuery) => {
      loading.push();
      try {
        const response = await axios.post(getServiceEndpoint(), data || query, {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
        });
        console.log("Full response data:", response.data);  // ตรวจสอบข้อมูลที่ได้จาก API
        if (response.data) {
          setCollection(response.data.data); // ใช้ setCollection เพื่ออัพเดตข้อมูล
        }
      } catch (error) {
        console.error('Error fetching collection:', error);
      } finally {
        loading.pop();
      }
    },
    [query, authContext, loading],
  );

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.post(getRolesEndpoint(), {}, {
        headers: {
          Authorization: `Bearer ${authContext.token}`,
        },
      });
      const roles = response.data.data.map((role: any) => ({ _id: role._id, roleName: role.roleName }));
      setRoles(roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  }, [authContext.token]);
  // เพิ่มฟังก์ชัน updateAdminRole
  const updateAdminRole = useCallback(async (id: string, roleId: string) => {
    try {
      const response = await axios.put(`${getServiceEndpoint()}/${id}/role`, { roleId }, {
        headers: {
          Authorization: `Bearer ${authContext.token}`,
        },
      });
      console.log('Role updated successfully', response.data);
    } catch (error) {
      console.error('Error updating admin role:', error);
    }
  }, [authContext.token]);

  return (
    <AdminListServiceContext.Provider
      value={{
        query,
        collection,
        roles,
        fetchRoles,
        onFetch,
        setCollection,
        updateAdminRole
      }}
    >
      {children}
    </AdminListServiceContext.Provider>
  );
};
