import { lazy } from "react";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";
import { Outlet } from "react-router";
import NotifticationService from "../contexts/services/notification-service/NotifiticationService";

const NotificationList = ScreenLoader(
  lazy(() => import("../screens/notification-screen/NotificationListScreen")),
);
const NotificationAdd = ScreenLoader(
  lazy(() => import("../screens/notification-screen/NotificationAddScreen")),
);

export const NotificationRoute = () => ({
  path: "notification",
  element: (
    <AuthGuard>
      <NotifticationService>
        <Outlet />
      </NotifticationService>
    </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: <NotificationList />,
    },
    {
      path: "add",
      element: <NotificationAdd />,
    },
  ],
});
