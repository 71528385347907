import { lazy } from "react";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";

const SellerList = ScreenLoader(
  lazy(() => import("../screens/seller-screen/SellerList")),
);

const SellerCreate = ScreenLoader(
  lazy(() => import("../screens/seller-screen/SellerCreate")),
);

const SellerReport = ScreenLoader(
  lazy(() => import("../screens/seller-screen/SellerReport")),
);

const SellerUserList = ScreenLoader(
  lazy(() => import("../screens/seller-screen/SellerUserList")),
);
//maping the seller route completely
export const SellerRoute = () => ({
  path: "seller",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: (
        <RoleGuard
          requiredPermission="sellerManagement"
          requiredActions={["isRead"]}
        >
          <SellerList />
        </RoleGuard>
      ),
    },
    {
      path: "create",
      element: (
        <RoleGuard
          requiredPermission="sellerManagement"
          requiredActions={["isCreate"]}
        >
          <SellerCreate />
        </RoleGuard>
      ),
    },
    {
      path: "update/:id",
      element: (
        <RoleGuard
          requiredPermission="sellerManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <SellerCreate />
        </RoleGuard>
      ),
    },
    {
      path: "report/:id",
      element: (
        <RoleGuard
          requiredPermission="sellerManagement"
          requiredActions={["isRead"]}
        >
          <SellerReport />
        </RoleGuard>
      ),
    },
    {
      path: "user/:id",
      element: (
        <RoleGuard
          requiredPermission="sellerManagement"
          requiredActions={["isRead"]}
        >
          <SellerUserList />
        </RoleGuard>
      ),
    },
  ],
});
